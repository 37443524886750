/**
 *
 * Asynchronously loads the component for RegisterUser
 *
 */

import loadable from 'react-loadable';
import Loading from '../../components/Loading';

export default loadable({
  loader: () => import('./index'),
  loading: Loading
});
