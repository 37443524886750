import axios from 'axios';
import { baseURL } from 'utils/env';
import { getStorageData } from './helper';

const instance = axios.create({
  baseURL,
  headers: {
    common: { 'Content-Type': 'application/json' }
  }
});
const handleError = error => {
  if (
    error &&
    error.response &&
    error.response.status &&
    error.response.status === 401
  ) {
    window.localStorage.clear();
    window.location.replace(`${window.location.origin}/login`);
  }
  throw error;
};
// global error handling
instance.interceptors.response.use(
  response => response,
  error => Promise.reject(handleError(error))
);

instance.interceptors.request.use(
  config => {
    const request = config;
    const user = getStorageData('user');
    if (user && user.token) {
      request.headers.common['access-token'] = user.token;
    }
    return request;
  },
  error => {
    return Promise.reject(error);
  }
);

export default instance;
