import { create } from 'axios';
import { baseURL } from 'utils/env';
import { serverErrors } from './handleServerError';
import { getStorageData } from 'utils/helper';

export const axios = create({
  baseURL,
  headers: {
    common: { 'Content-Type': 'application/json' }
  }
});

axios.interceptors.response.use(
  response => successResponce(response),
  error => handleErrors(error)
);

axios.interceptors.request.use(
  config => {
    const request = config;
    const user = getStorageData('user');
    if (user && user.token) {
      request.headers.common['access-token'] = user.token;
    }
    return request;
  },
  error => {
    return Promise.reject(error);
  }
);
const successResponce = result => {
  const { data = {} } = result;
  return data;
};
const handleErrors = error => {
  if (
    error &&
    error.response &&
    error.response.status &&
    error.response.status === 403
  ) {
    window.localStorage.clear();
    window.location = '/login';
  }
  return Promise.reject(serverErrors(error));
};
