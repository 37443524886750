import React, { Suspense, lazy } from 'react';
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch
} from 'react-router-dom';
import Typekit from 'react-typekit';
import ErrorBoundary from 'components/ErrorBoundry';
import HomePage from 'containers/MainPage/Loadable';
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import LogIn from 'containers/LogIn/Loadable';
import RegisterCompany from 'containers/RegisterCompany/Loadable';
import CompleteProfileCompany from 'containers/CompleteProfileCompany/Loadable';
import ProfessionalAttendance from 'containers/LucyFlow/Loadable';
import ScrollToTop from 'components/ScrollToTop';
import Welcome from 'containers/Welcome/Loadable';
import ForgotPassword from 'containers/ForgotPassword/Loadable';
import ResetPassword from 'containers/ResetPassword/Loadable';
import EventFlow from 'containers/EventFlow/Loadable';
import CancelAttendance from 'containers/CancelAttendance/Loadable';
import LandingPageRedirect from 'components/LandingPageRedirect';
import 'semantic/semantic.css';
import 'styles/common.scss';
import { isProduction } from 'utils/env';
import Verify from 'pages/EmailVerification';
import Loading from 'components/Loading';
import { webUrl } from 'constants/app';

const HomeComponent = isProduction ? LandingPageRedirect : HomePage;

const AdminDashboard = lazy(() =>
  import(/* webpackChunkName: "Admin-Dashboard */ 'pages/Admin')
);
const Employer = lazy(() =>
  import(/* webpackChunkName: "Employer-Dashboard" */ 'pages/Employer')
);
const Professional = lazy(() =>
  import(/* webpackChunkName: "Professional-Dashboard" */ 'pages/Professional')
);
const SMEDashboard = lazy(() =>
  import(/* webpackChunkName: "SMEDashboard-Dashboard" */ 'pages/SMEDashboard')
);
const EventSummary = lazy(() =>
  import(/* webpackChunkName: "EventSummary-page" */ 'pages/EventSummary')
);
const RegisterSME = lazy(() =>
  import(/* webpackChunkName: "RegisterSME-page" */ 'pages/RegisterSME')
);
const RegisterST = lazy(() =>
  import(/* webpackChunkName: "RegisterST-page" */ 'pages/RegisterST')
);
const EmployeeDashboard = lazy(() =>
  import(/* webpackChunkName: "Employee-Dashboard" */ 'pages/EmployeeDashboard')
);
const EmployeeDashboardRedirect = lazy(() =>
  import(
    /* webpackChunkName: "Employee-Dashboard-Redirect" */ 'pages/EmployeeDashboard/Redirect'
  )
);
const LibraryView = lazy(() =>
  import(/* webpackChunkName: "Library-View" */ 'pages/LibraryView')
);
const IndividualRedirect = lazy(() =>
  import(/* webpackChunkName: "Library-View" */ 'pages/IndividualRedirect')
);
const RegisterEmployee = lazy(() =>
  import(/* webpackChunkName: "RegisterEmployee" */ 'pages/RegisterEmployee')
);
const DirectRegisterEmployee = lazy(() =>
  import(
    /* webpackChunkName: "DirectRegisterEmployee" */ 'pages/RegisterEmployeeDirectly'
  )
);
const GuestSessionDetail = lazy(() =>
  import(
    /* webpackChunkName: "GuestSession" */ 'pages/GuestSessionFeedbackModule'
  )
);

const ExperianCancelRequest = lazy(() =>
  import(
    /* webpackChunkName: "ExperianCancelRequest" */ 'pages/ExperianCancelPage'
  )
);
const AwinDataRequest = lazy(() =>
  import(/* webpackChunkName: "AwinDataRequest" */ 'pages/AwinDataRequestPage')
);
const RakutanDataRequest = lazy(() =>
  import(/* webpackChunkName: "RakutanDataRequest" */ 'pages/RakutanDataRequestPage')
);
class App extends React.PureComponent {
  render() {
    return (
      <>
        <Router>
          <div className="root">
            <ScrollToTop />
            <ErrorBoundary>
              <Suspense fallback={<Loading />}>
                <Switch>
                  <Route path="/st-register" component={RegisterST} />
                  <Route path="/verify-email/:token" component={Verify} />
                  <Route
                    path="/verify-personal-email/:token"
                    render={props => (
                      <Verify {...props} personalEmailVerification />
                    )}
                  />
                  <Route exact path="/register-user" component={RegisterSME} />
                  <Route path="/login" component={LogIn} />
                  <Route path="/view-library-login/:id" component={LogIn} />
                  <Route path="/admin" component={AdminDashboard} />
                  <Route path="/professional" component={Professional} />
                  <Route path="/sme-dashboard" component={SMEDashboard} />
                  <Route
                    path="/employee-dashboard"
                    component={EmployeeDashboardRedirect}
                  />
                  <Route
                    path="/individual-dashboard"
                    component={EmployeeDashboard}
                  />
                  <Route
                    exact
                    path="/event-attendance/:bookingId"
                    component={ProfessionalAttendance}
                  />
                  <Route path="/employer" component={Employer} />
                  <Route path="/register-company" component={RegisterCompany} />
                  <Route
                    exact
                    path="/employer-complete-profile/:token"
                    component={CompleteProfileCompany}
                  />
                  <Route
                    exact
                    path="/view-library/:id"
                    component={LibraryView}
                  />
                  <Route
                    exact
                    path="/individual-redirect/:page_name"
                    component={IndividualRedirect}
                  />
                  <Route
                    path="/register-employee/:companyToken?"
                    component={RegisterEmployee}
                  />
                  <Route
                    path="/employee-signup/:publicId"
                    component={DirectRegisterEmployee}
                  />
                  <Route exact path="/welcome" component={Welcome} />
                  <Route
                    excat
                    path="/forgot-password"
                    component={ForgotPassword}
                  />
                  <Route
                    exact
                    path="/reset-password/:token"
                    component={ResetPassword}
                  />
                  <Route exact path="/event/:id" component={EventFlow} />
                  <Route
                    exact
                    path="/event-summary/:id"
                    component={EventSummary}
                  />
                  <Route
                    exact
                    path="/cancel-attendance/:token"
                    component={CancelAttendance}
                  />
                  <Route
                    exact
                    path="/logout"
                    render={() => {
                      window.localStorage.removeItem('user');
                      return <Redirect to="/" />;
                    }}
                  />
                  <Route
                    exact
                    path="/guest-session-feedback/:token"
                    component={GuestSessionDetail}
                  />
                  <Route
                    path="/terms"
                    render={() => {
                      window.location.replace(`${ webUrl }/terms-conditions`);
                      return null;
                    }}
                  />
                  <Route
                    path="/privacy-policy"
                    render={() => {
                      window.location.replace(`${ webUrl }/privacy-policy`);
                      return null;
                    }}
                  />
                  <Route
                    exact
                    path="/experian-cancel-request/:token"
                    component={ExperianCancelRequest}
                  />
                  <Route exact path="/awin-data" component={AwinDataRequest} />
                  <Route exact path="/rakutan-data" component={RakutanDataRequest} />
                  <Route exact path="/" component={HomeComponent} />
                  <Route path="" component={NotFoundPage} />
                </Switch>
              </Suspense>
            </ErrorBoundary>
          </div>
        </Router>
        <Typekit kitId="gsq4qwr" />
      </>
    );
  }
}

export default App;
