import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  head: {
    marginBottom: '24px',
    '& h2': {
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '32px',
      marginBottom: 0,
      paddingBottom: 8
    },
    '& .subheading': {
      fontSize: '16px',
      lineHeight: '24px',
      color: ({ secondaryColor }) => secondaryColor
    }
  }
});
