export const step2Initial = {
  company_name: '',
  website: '',
  company_registration_number: '',
  billing_vat_number: '',
  service_offer: [],
  first_name: '',
  last_name: '',
  mobile: '',
  point_of_contact_role: '',
  registration_step: 2
};

export const step2ErrorInitial = {
  company_name: '',
  website: '',
  company_registration_number: '',
  billing_vat_number: '',
  service_offer: '',
  first_name: '',
  last_name: '',
  mobile: '',
  point_of_contact_role: ''
};
