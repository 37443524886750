import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { logo, signout } from 'images';
import { getStorageData } from 'utils/helper';

const AdminHeader = ({
  logoutOnly,
  logoImage,
  logout,
  companyLogo,
  companySummaryPage
}) => {
  const { pathname } = useLocation();
  const user = getStorageData('user');
  const logoRedirectUrl = () => {
    if (pathname.indexOf('event-summary') > -1) {
      return pathname;
    }
    if (!user.dashboard) return companySummaryPage;
    return '/login';
  };

  return (
    <>
      <div className="admin-header-container">
        <div className="logo-col">
          <Link to={logoRedirectUrl()}>
            <img
              src={companyLogo || logoImage}
              width="110"
              className="logo logo-custom"
              alt="Lyfe"
            />
          </Link>
        </div>
        {logoutOnly && (
          <div className="admin-head-col">
            <img
              src={signout}
              width="30"
              onClick={logout}
              className="logout"
              alt="logout"
            />
          </div>
        )}
      </div>
    </>
  );
};

AdminHeader.propTypes = {
  adminType: PropTypes.number,
  logout: PropTypes.func,
  logoutOnly: PropTypes.bool,
  logoImage: PropTypes.string,
  companyLogo: PropTypes.string,
  companySummaryPage: PropTypes.string
};

AdminHeader.defaultProps = {
  adminType: 0,
  logoutOnly: false,
  logoImage: logo,
  logout: () => false,
  companyLogo: '',
  companySummaryPage: '/'
};

export default AdminHeader;
