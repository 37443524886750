import React, { memo } from 'react';
import propTypes from 'prop-types';
import { useTheme } from 'react-jss';
import Helmet from 'react-helmet';
import AdminHeader from 'components/AdminHeader';

const NOUSER = 0;
const Layout = props => {
  const { Component, classes } = props;
  const { logo } = useTheme();
  return (
    <>
      <div className={`grey-body ${classes.fullHeight}`}>
        <Helmet>
          <title>Lyfe session registration event flow</title>
          <meta name="description" content="Lyfe" />
        </Helmet>
        <div className="full-header">
          <AdminHeader adminType={NOUSER} companyLogo={logo} />
        </div>

        <section className={classes.mainView}>
          <Component {...props} />
        </section>
      </div>
    </>
  );
};

Layout.propTypes = {
  Component: propTypes.any.isRequired,
  classes: propTypes.object.isRequired
};
export default memo(Layout);
