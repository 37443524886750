import React from 'react';
import propTypes from 'prop-types';
import { withTheme } from 'react-jss';
import { useStyles } from './style';
const Spacer = ({ width, height }) => {
  const classes = useStyles({ width, height });
  return <div className={classes.spacer}></div>;
};

Spacer.propTypes = {
  theme: propTypes.object,
  width: propTypes.string,
  height: propTypes.string
};
Spacer.defaultProps = {
  width: '100%',
  height: '0',
  theme: {}
};

export default withTheme(Spacer);
