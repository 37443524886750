import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  mainColorHeading: {
    color: ({ mainColor }) => mainColor,
    padding: '10px 0'
  },
  extraColorHeading: {
    color: ({ extraColor }) => extraColor,
    padding: '10px 0'
  },
  blueHeading: {
    color: ({ otherThemeColors }) => otherThemeColors.blue,
    padding: '10px 0'
  },
  blackHeading: {
    color: ({ otherThemeColors }) => otherThemeColors.baseBlack
  }
});
