import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  button: {
    textAlign: 'center',
    padding: '0px',
    height: '32px',
    width: ({ width }) => width,
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    borderRadius: '76px',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none'
    },
    '&:disabled': {
      backgroundColor: ({ otherThemeColors }) => otherThemeColors.grayMedium,
      color: ({ buttonColor }) => buttonColor,
      border: ({ otherThemeColors }) =>
        `1px solid ${otherThemeColors.grayMedium}`
    }
  },
  primaryButton: {
    background: ({ mainColor }) => mainColor,
    color: ({ buttonColor }) => buttonColor,
    border: ({ mainColor }) => `2px solid ${mainColor}`
  },
  secondaryButton: {
    background: ({ otherThemeColors }) => otherThemeColors.white,
    color: ({ extraColor }) => extraColor,
    border: ({ extraColor }) => `2px solid ${extraColor}`
  },
  blueBorderButton: {
    background: ({ otherThemeColors }) => otherThemeColors.white,
    color: ({ otherThemeColors }) => otherThemeColors.blue,
    border: ({ otherThemeColors }) => `2px solid ${otherThemeColors.blue}`
  },
  deleteButton: {
    background: ({ otherThemeColors }) => otherThemeColors.white,
    color: ({ otherThemeColors }) => otherThemeColors.red,
    border: ({ otherThemeColors }) => `2px solid ${otherThemeColors.red}`
  }
});
