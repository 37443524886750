export const step3Initial = {
  address: '',
  address1: '',
  city: '',
  postcode: '',
  regions: [],
  registration_step: 3
};

export const step3ErrorInitial = {
  address: '',
  address1: '',
  city: '',
  postcode: '',
  regions: ''
};
