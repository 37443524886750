import React from 'react';
import PropTypes from 'prop-types';
import Heading from 'components/Forms/Heading';
import { withTheme } from 'react-jss';
import { useStyles } from './style';
import { overrideClassHandler } from 'utils/helper';

const SectionHeadText = ({
  theme,
  heading,
  subHeading,
  headingProps,
  subHeadingProps,
  classNames
}) => {
  const classes = useStyles(theme);
  const container = overrideClassHandler('head', classes, classNames);

  return (
    <div className={container}>
      {heading && <Heading {...headingProps}>{heading}</Heading>}
      {subHeading && (
        <div className="subheading" {...subHeadingProps}>
          {subHeading}
        </div>
      )}
    </div>
  );
};

SectionHeadText.propTypes = {
  theme: PropTypes.object.isRequired,
  heading: PropTypes.string.isRequired,
  headingProps: PropTypes.object,
  classNames: PropTypes.object,
  subHeadingProps: PropTypes.object,
  subHeading: PropTypes.string
};
SectionHeadText.defaultProps = {
  headingProps: { as: 'h2' },
  classNames: {},
  subHeadingProps: {},
  subHeading: ''
};
export default withTheme(SectionHeadText);
