import axios from 'utils/configureAxios';
import {
  EVENT_DETAIL,
  PUBLIC_EVENTS_LIST,
  PUBLIC_COMPANY_EVENTS_FILTERS,
  EMPLOYEE_COMPANY_EVENTS_FILTERS,
  EVENT_BULK_REGISTER,
  EMAIL_VERIFY,
  RESEND_VERIFICATION_EMAIL,
  VERIFY_PERSONAL_EMAIL,
  PUBLIC_COMPANY_LIST,
  EMPLOYEE_EVENT_BULK_REGISTER,
  GENERAL_FEEDBACK,
  EMPLOYEE_SESSION_FEEDBACK,
  EMPLOYEE_EVENTS,
  EMPLOYEE_EVENT_CANCEL,
  EMPLOYEE_BOOKINGS,
  EMPLOYEE_PAST_BOOKINGS,
  EMPLOYEE_INTEREST_CHECK,
  EMPLOYEE_ATTENDENCE,
  CHECK_EMPLOYEE_BOOKING,
  USER_LYFE_TERMS,
  ACCEPT_USER_LYFE_TERMS,
  INTEREST_REWARD_LISTING,
  INTEREST_GROUP_LIBRARY,
  GET_PUBLIC_ID,
  USER_ADDRESS,
  PERSONALISATION,
  CANCEL_EXPERIAN_PIN,
  EXPERIAN_SUBSCRIPTION_REPORT,
  AWIN_DATA_REPORT,
  RAKUTAN_DATA_REPORT
} from 'utils/URLs';
import {get, post, put, deleteApi} from 'utils/v2/api';

export const getEventDetail = bookingId => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${ EVENT_DETAIL }/${ bookingId }`)
      .then(res => {
        resolve({status: true, data: res.data.data});
      })
      .catch(error => {
        const errMsg =
          error && error.response.data.message
            ? error.response.data.message
            : 'Oops something went wrong..';
        reject({status: false, error: errMsg});
      });
  });
};

export const publicEventsList = (id, params) =>
  get(`${ PUBLIC_EVENTS_LIST }/${ id }`, params);

export const publicCompanyEvents = id =>
  get(`${ PUBLIC_COMPANY_EVENTS_FILTERS }/${ id }`);

export const employeeCompanyEvents = id =>
  get(`${ EMPLOYEE_COMPANY_EVENTS_FILTERS }/${ id }`);

export const EmployeeEvents = (id, params) =>
  get(`${ EMPLOYEE_EVENTS }/${ id }`, params);

export const EmployeeBookings = id => get(`${ EMPLOYEE_BOOKINGS }/${ id }`);

export const eventBulkRegister = data => post(`${ EVENT_BULK_REGISTER }`, data);

export const checkEmployeeBooking = data =>
  post(`${ CHECK_EMPLOYEE_BOOKING }`, data);

export const employeeEventBulkRegister = data =>
  post(`${ EMPLOYEE_EVENT_BULK_REGISTER }`, data);

export const employeeSessionFeedback = data =>
  post(`${ EMPLOYEE_SESSION_FEEDBACK }`, data);

export const generalFeedback = data => post(`${ GENERAL_FEEDBACK }`, data);

export const getGeneralFeedback = id => get(`${ GENERAL_FEEDBACK }/${ id }`);

export const employeeAttendence = data => post(`${ EMPLOYEE_ATTENDENCE }`, data);

export const employeeEventCancel = data =>
  post(`${ EMPLOYEE_EVENT_CANCEL }`, data);

export const verifyEmail = data => post(`${ EMAIL_VERIFY }`, data);

export const verifyPersonalEmail = data =>
  post(`${ VERIFY_PERSONAL_EMAIL }`, data);

export const resendVerificationEmail = id =>
  get(`${ RESEND_VERIFICATION_EMAIL }/${ id }`);

export const resendVerificationEmailPersonal = (id, params) =>
  get(`${ RESEND_VERIFICATION_EMAIL }/${ id }`, params);

export const publicCompanyList = params =>
  get(`${ PUBLIC_COMPANY_LIST }`, params);

export const employeePastBooking = id => get(`${ EMPLOYEE_PAST_BOOKINGS }/${ id }`);

export const employeeInterestCheck = data =>
  post(`${ EMPLOYEE_INTEREST_CHECK }`, data);

export const getUserLyfeTerms = user_id => get(`${ USER_LYFE_TERMS }/${ user_id }`);

export const acceptUserLyfeTerms = user_id =>
  put(`${ ACCEPT_USER_LYFE_TERMS }/${ user_id }`);

export const interestRewardListing = id =>
  get(`${ INTEREST_REWARD_LISTING }/${ id }`);

export const deleteContent = id => deleteApi(`${ INTEREST_GROUP_LIBRARY }/${ id }`);

export const getPublicRegistrationUrl = id => get(`${ GET_PUBLIC_ID }/${ id }`);

export const updateUserAddress = data =>
  put(`${ USER_ADDRESS }/${ data.user_id }`, data);

export const getPersonalisation = id => {
  return get(`${ PERSONALISATION }/${ id }`);
};

export const cancelExperianSubscription = data => {
  return put(`${ CANCEL_EXPERIAN_PIN }`, data);
};

export const experianSubscriptionReport = data => {
  return get(EXPERIAN_SUBSCRIPTION_REPORT, data);
};

export const awinDataRequest = () => {
  return get(`${ AWIN_DATA_REPORT }`);
};
export const rakutanDataRequest = (data) => {
  return get(`${ RAKUTAN_DATA_REPORT }`, data);
};
