import React from 'react';
import { ThemeProvider } from 'react-jss';
import theme from 'theme';
import Layout from './Layout';
import { useStyles } from './style';
import VerifyEmail from 'components/VerifyEmail';

const EmailVerification = props => {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <Layout {...props} Component={VerifyEmail} classes={classes} />
    </ThemeProvider>
  );
};

export default EmailVerification;
