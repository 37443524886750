export const getServerFlag = (hostname = window.location.hostname) => {
  const productionHostnames = [
    'openmindwellbeing.com',
    'www.openmindwellbeing.com',
    'app.lyfetech.io'
  ];

  if (productionHostnames.some(url => url === hostname)) {
    return 'production';
  }
  return 'development';
};

export const getServerUrl = (hostname = window.location.hostname) => {
  if (hostname === 'localhost') {
    return `http://${window.location.hostname}:${process.env.REACT_APP_PORT}/`;
  }
  return '/';
};
