import React from 'react';
import propTypes from 'prop-types';

const CircularImage = ({ url, width, height, alt, isSquare, ...rest }) => {
  const borderRadius = isSquare ? '0%' : '50%';
  const style = { width, height, borderRadius };
  return <img src={url} style={style} alt={alt} {...rest} />;
};

CircularImage.propTypes = {
  url: propTypes.string.isRequired,
  width: propTypes.string,
  height: propTypes.string,
  alt: propTypes.string,
  isSquare: propTypes.bool
};
CircularImage.defaultProps = {
  width: '60px',
  height: '40px',
  alt: 'image',
  isSquare: true
};

export default CircularImage;
