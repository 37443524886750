import React from 'react';
import propTypes from 'prop-types';
import { useStyles } from './style';
import { withTheme } from 'react-jss';
import classnames from 'classnames';
const Button = ({
  theme,
  children,
  onClick,
  className,
  disabled,
  primaryButton,
  secondaryButton,
  blueBorderButton,
  deleteButton,
  width,
  ...rest
}) => {
  const classes = useStyles({ width, ...theme });
  return (
    <button
      disabled={disabled}
      className={classnames(classes.button, className, {
        [classes.primaryButton]: primaryButton,
        [classes.secondaryButton]: secondaryButton,
        [classes.blueBorderButton]: blueBorderButton,
        [classes.deleteButton]: deleteButton
      })}
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  );
};
Button.propTypes = {
  theme: propTypes.object.isRequired,
  children: propTypes.any.isRequired,
  onClick: propTypes.func,
  className: propTypes.string,
  disabled: propTypes.bool,
  primaryButton: propTypes.bool,
  secondaryButton: propTypes.bool,
  blueBorderButton: propTypes.bool,
  deleteButton: propTypes.bool,
  width: propTypes.string
};
Button.defaultProps = {
  onClick: () => false,
  className: '',
  disabled: false,
  primaryButton: false,
  secondaryButton: false,
  blueBorderButton: false,
  deleteButton: false,
  width: '200px'
};
export default withTheme(Button);
