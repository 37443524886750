import React from 'react';
import propTypes from 'prop-types';
import { useStyles } from './style';
import { otherThemeColors } from '../../../constants/app';

const WhiteBox = ({ children, borderRadius, className }) => {
  let customBorderRadius = '8px';

  if (borderRadius === 'topOnly') {
    customBorderRadius = '8px 8px 0px 0px';
  } else if (borderRadius === 'bottomOnly') {
    customBorderRadius = '0px 0px 8px 8px';
  } else if (borderRadius === 'none') {
    customBorderRadius = '0';
  }

  const classes = useStyles({
    customBorderRadius,
    white: otherThemeColors.white
  });

  return (
    <div className={`${classes.whiteBoxContainer} ${className}`}>
      {children}
    </div>
  );
};

WhiteBox.propTypes = {
  children: propTypes.any.isRequired,
  className: propTypes.string,
  borderRadius: propTypes.string
};

WhiteBox.defaultProps = {
  borderRadius: '',
  className: ''
};

export default WhiteBox;
