export default {
  LARGE_DESKTOP: '@media (min-width: 1600px)',
  MEDIUM_DESKTOP: '@media (min-width: 1368px) and (max-width: 1477px)',
  MEDIUM_DESKTOP_TWO: '@media (min-width: 1523px) and (max-width: 1599px)',
  MEDIUM_DESKTOP_THREE: '@media (min-width: 1478px) and (max-width: 1522px)',
  SMALL_DESKTOP: '@media (min-width:1025px) and (max-width: 1367px)',
  IPAD: '@media (min-width:768px) and (max-width:991px)',
  IPAD_LANDSCAPE: '@media (min-width:991px) and (max-width:1024px)',
  MOBILE_DEVICE: '@media (min-width:320px) and (max-width: 767px)',
  MOBILE_HEIGHT: '@media (max-height:480px)',
  IPAD_AND_SMALLER: '@media (max-width:1024px)',
  RESPONSIVE: '@media only screen and (max-width: 768px)',
  MIN_IPAD: '@media (min-width: 762px)',
  MAX_IPAD: '@media (max-width: 762px)'
};
