import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  main: {
    maxWidth: '640px',
    borderRadius: '12px',
    backgroundColor: ({ theme }) => theme.otherThemeColors.white,
    alignItems: 'center',
    width: '100%',
    padding: '40px',
    marginTop: '10%',
    marginBottom: '20px',
    margin: '0 auto',
    textAlign: 'center'
  },
  faceImage: {
    width: '150px'
  },
  heading: {
    color: '#3C3E44',
    letterSpacing: '1px',
    lineHeight: '40px',
    fontFamily: 'univia-pro',
    margin: '20px 25px'
  },
  p: {
    fontSize: '14px',
    lineHeight: '20px',
    margin: '30px 0'
  },
  button: {
    width: '130px'
  }
});
