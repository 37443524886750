import colors, { defaultColors, otherThemeColors } from './colors';
import fonts from './fonts';
import breakpoints from './breakpoints';
import { getStorageData } from 'utils/helper';
import { logo } from '../images';
import { employeeBg } from '../images';

export const selectedThemeColor = () => {
  const user = getStorageData('user');
  const localColors = user.colors ? JSON.parse(user.colors) : {};
  const colors =
    Object.keys(localColors).length > 0 ? localColors : defaultColors;
  return {
    ...colors,
    logoImage: user.profile_picture || logo,
    bannerImage: employeeBg
  };
};
export const resetColors = { defaultColors, otherThemeColors };
export default {
  fonts,
  breakpoints,
  ...colors
};
