import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  fullHeight: {
    height: '100%'
  },
  smeRegisterStep: {
    height: '100vh auto'
  }
});
