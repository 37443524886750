export const SAVE_SUBSCRIBER = 'subscribe';
export const SAVE_REGISTER_USER = 'register';
export const UPDATE_REGISTER_USER = 'user';
export const FILE_UPLOADER = 'file';
export const USER_LOGIN = 'login';
export const AUTH_CHECK = 'auth';
export const LOGOUT = 'logout';
export const VERIFY_EMAIL = 'verify-user';
export const USER_DETAIL = 'user';
export const COMPLETE_PROFILE = 'user';
export const VERIFY_TOKEN = 'verify-email';
export const EMPLOYER_AVAILABLE_SESSION = 'sessions';
export const EMPLOYER_BOOK_SESSION = 'booking';
export const FORGOT_PASSWORD = 'forgotpassword';
export const VALIDATE_TOKEN = 'validate-token';
export const RESET_PASSWORD = 'resetpassword';
export const EVENT_DETAIL = 'event';
export const PUBLIC_EVENTS_LIST = 'events';
export const PUBLIC_COMPANY_EVENTS_FILTERS = 'company-event-filters';
export const EMPLOYER_SESSIONS = 'user';
export const USER_ADDRESS = 'user-address';
export const REGISTER_EVENT = 'event-register';
export const PROFESSIONAL_SESSIONS = 'professional';
export const ADMIN_SESSIONS = 'bookings';
export const CONFIRM_BOOKING = 'booking-confirm';
export const ALL_PROFESSIONAL = 'professionals';
export const ADMIN_COMPANY = 'company';
export const EMPLOYER = 'employer';
export const INTEREST_GROUP_USER = 'interest-group';
export const ADMIN_PAYMENT_CONFIRM = 'booking-payment';
export const CONFIRM_MULTIPLE_PAYMENTS = 'booking-payments';
export const ATTENDEES_REGISTER = 'booking-register';
export const CANCEL_ATTENDANCE = 'cancel-attendance';
export const REQUEST_QUOTE = 'quote';
export const SPECIAL_EVENT = 'special-event';
export const EMPLOYER_LIST = 'employers';
export const ADMIN_SPECIAL_EVENT = 'special-quote-event';
export const GET_UPLOAD_TOKEN = 'get-upload-token';
export const GET_PUBLIC_UPLOAD_TOKEN = 'get-public-upload-token';
export const COMPANY_PERSONALISATION = 'company-personalisation';
export const PERSONALISATION = 'personalisation';
export const COMPANY_NOTES = 'note';
export const UPDATE_ATTENDEE_VISIBILITY = 'update-attendee-visibility';
export const UPDATE_EVENT_VISIBILITY = 'show-event-on-summary-page';
export const POWERBI_REPORT_REQUEST = 'request-powerBi-dashboard';
export const GET_SME_LIST = 'sme-list';
export const SME_DETAILS = 'sme';
export const SME_REGISTERATION_STEP1 = 'register-sme';
export const SME_ONBOARDING = 'complete-sme-register';
export const GET_DELIVERY_REGIONS = 'regions';
export const USER_SERVICE_OFFERING = 'user/service';
export const ALL_USER_SERVICE_OFFERINGS = 'user-services';
export const SERVICES_WITH_CATEGORY_LIST = 'services-with-categories';
export const GET_SERVICES_CATEGORIES = 'services-categories';
export const SERVICES = 'services';
export const EVENT_BULK_REGISTER = 'event-bulk-register';
export const EMPLOYEE_EVENT_BULK_REGISTER = 'employee-event-bulk-register';
export const EMPLOYEE_EVENT_CANCEL = 'cancel-attendance';
export const EMAIL_VERIFY = 'verify-email';
export const RESEND_VERIFICATION_EMAIL = 'resend-email-verification';
export const VERIFY_PERSONAL_EMAIL = 'verify-personal-email';
export const ST_ONBOARDING = 'complete-st-register';
export const FILE_DELETE = 'delete-s3-files';
export const ST_DETAIL = 'st';
export const ST_LISTING = 'st-list';
export const ST_DELETE = 'professional';
export const PUBLIC_COMPANY_LIST = 'public-company-list';
export const EMPLOYEE_REGISTER = 'employee-register';
export const EMPLOYEE_UPDATE = 'employee';
export const EMPLOYEE_EVENTS = 'employee-events';
export const EMPLOYEE_COMPANY_EVENTS_FILTERS = 'interest-event-filters';
export const INTEREST_GROUP = 'company-interest-group';
export const EMPLOYEE_BOOKINGS = 'employee-booking';
export const EMPLOYEE_PAST_BOOKINGS = 'employee-past-booking';
export const EMPLOYEE_CHECKIN_AVG = 'checkin-average';
export const EMPLOYEE_CHECKIN = 'employee-checkin';
export const EMPLOYEE_SESSION_FEEDBACK = 'employee-session-feedback';
export const GENERAL_FEEDBACK = 'general-feedback';
export const EMPLOYEE_ATTENDENCE = 'employee-attendance';
export const EMPLOYEE_COMPANY_INTEREST = 'employee-interest';
export const CHECK_EMPLOYEE_BOOKING = 'check-employee-booking';
export const EMPLOYEE_DELETE = 'employee';
export const EMPLOYEE_INTEREST_CHECK = 'employee-interest-check';
export const USER_LYFE_TERMS = 'user-lyfe-terms';
export const ACCEPT_USER_LYFE_TERMS = 'accept-user-lyfe-terms';
export const GUEST_SESSION_DETAILS = 'guest-session-details';
export const ACTIVE_VOTING = 'active-voting';
export const REGISTER_VOTING = 'register-voting';
export const EMPLOYEE_VOTING_LISTING = 'employee-voting-listing';
export const REGISTER_EMPLOYEE_VOTING = 'register-employee-voting';
export const VOTING_HISTORY = 'voting-history';
export const EMPLOYEE_REWARDS = 'employee-rewards';
export const LUX_RESTAURANTS = 'lux-restaurants-list';
export const EMPLOYEE_CARD = 'lux-card';
export const EMPLOYEE_LUX_ID = 'employee-lux-id';
export const GAMIFICATION_ON = 'rewards-on';
export const INTEREST_REWARD_LISTING = 'interest-rewards-listing';
export const REWARDS_CHECK = 'check-rewards-on';
export const LUX_TRANSACTIONS = 'lux-transactions';
export const WELLBEING_TRANSACTIONS = 'wellbeing-transactions';
export const EMP_SERVICES = 'emp-services';
export const PROVIDER_LIST = 'provider-list';
export const PUBLIC_EVENT_REWARDS = 'public-event-rewards-on';
export const EMPLOYEE_EVENT_CREATE = 'employee-event-create';
export const INTEREST_GROUP_EMPLOYEES = 'interest-group-employees';
export const CLAIM_LUX_REWARDS = 'claim-lux-rewards';
export const EDIT_PERSONAL_EMAIL = 'edit-personal-email';
export const INTEREST_GROUP_LIBRARY = 'interest-group-library';
export const GET_USER_INTERESTGROUP = 'get-user-interestgroup';
export const EMPLOYEE_LIBRARY_FEEDBACK = 'employee-library-feedback';
export const LIBRARY_FEEDBACK = 'library-feedback';
export const LIBRARY_FEEDBACK_DETAILS = 'library-feedback-details';
export const SHARE_LIBRARY = 'share-library';
export const INTEREST_GROUP_SIGNPOSTING = 'interest-group-signposting';
export const LIBRARY_TRACKING = 'library-tracking';
export const PUBLIC_INTEREST_GROUP = 'public-interest-group';
export const GET_PUBLIC_ID = 'get-public-id';
export const CHECKIN_WORD_CLOUD = 'checkin-word-cloud';
export const PERSONALINSIGHTS = 'personal-insights';
export const WORKPLACEINSIGHTS = 'workplace-insights';
export const CHECKINGRAPH = 'checkin-graph';
export const EMPLOYEE_WELLNESS_TIME = 'employee-wellness-time';
export const INSIGHTS_GENERAL_FEEDBACK = 'insight-general-feedback';
export const INSIGHTS_SESSION_FEEDBACK = 'insight-session-feedback';
export const SELF_CARE_GRAPH = 'self-care-graph';
export const EMPLOYER_INSIGHTS_OVERVIEW = 'employer-insights-overview';
export const EMPLOYER_OVERVIEW_GRAPH = 'employer-overview-graph';
export const EMPLOYER_WORD_CLOUD = 'employer-word-cloud';
export const EMPLOYER_CHECKIN_GRAPH = 'employer-checkin-graph';
export const EMPLOYER_WELLBEINGS = 'employer-wellbeings';
export const EMPLOYER_WELLNESS_TIME = 'employer-wellness-time';
export const EMPLOYER_LIBRARY_CATEGORIES = 'employer-library-categories';
export const EMPLOYER_SELF_CARE_GRAPH = 'employer-self-care-graph';
export const EMPLOYER_SESSIONS_TAB = 'employer-sessions';
export const CATEGORY_SESSION_GRAPH = 'category-session-graph';
export const COMPANY_INTERESTGROUP_LIST = 'company-inerestgroup-list';
export const PROFESSIONAL_SESSION_FEEDBACK = 'professional-session-feedback';
export const INSIGHT_OVERVIEW_KPIS = 'insight-overview-kpis';
export const INSIGHT_OVERVIEW_KPIS_GRAPH = 'insight-kpi-graph';
export const SERVICE_CATEGORY = 'service-category';
export const INDIVIDIUAL_PREFERENCES = 'preferences-individuals';
export const EMPLOYEE_EXPORT = 'employee-export';
export const EMPLOYEE_BONUS = 'employee-bonus';
export const CHANGE_WORK_EMAIL = 'change-work-email';
export const EMPLOYER_EMPLOYEE = 'employer-employee';
export const ALLOCATE_REWARDS = 'allocate-rewards';
export const EMPLOYE_REGISTER_NETWORKB = 'networkb-registration';
export const CASHBACK_TOKEN = 'cashback-token';
export const CANCEL_EXPERIAN_PIN = 'cancel-exp-pin';
export const EXPERIAN_SUBSCRIPTION_REPORT = 'experian-subscription-report';
export const COMPANY_EMPLOYEE_REWARD_CRITERIA =
  'company-employee-reward-criteria';
export const AWIN_DATA_REPORT = 'awin-data';
export const RAKUTAN_DATA_REPORT = 'rakutan-data';
