import { Message } from 'semantic-ui-react';
import React from 'react';
import propTypes from 'prop-types';
import withStyles, { withTheme } from 'react-jss';
import { style } from './style';

const CustomMessage = ({
  classes,
  content,
  showDismiss,
  onDismiss,
  success,
  ...rest
}) => {
  rest = { ...rest, success, negative: !success };
  if (showDismiss) rest = { ...rest, onDismiss };
  return <Message className={classes.content} content={content} {...rest} />;
};

CustomMessage.propTypes = {
  classes: propTypes.object.isRequired,
  content: propTypes.string.isRequired,
  onDismiss: propTypes.func,
  showDismiss: propTypes.bool,
  success: propTypes.bool
};
CustomMessage.defaultProps = {
  onDismiss: () => {},
  showDismiss: true,
  success: false
};
export default withStyles(style)(withTheme(CustomMessage));
