import React from 'react';
import Proptypes from 'prop-types';
import { useStyles } from './style';
import { withTheme } from 'react-jss';
const Heading = ({ children, as, theme, type, className, ...props }) => {
  const classes = useStyles(theme);

  /* type =
  mainColorHeading,
  extraColorHeading,
  blueHeading,
  */

  const setType = classes[type] ? classes[type] : className;

  switch (as) {
    case 'h1':
      return (
        <h1 className={setType} {...props}>
          {children}
        </h1>
      );
    case 'h2':
      return (
        <h2 className={setType} {...props}>
          {children}
        </h2>
      );
    case 'h3':
      return (
        <h3 className={setType} {...props}>
          {children}
        </h3>
      );
    case 'h4':
      return (
        <h4 className={setType} {...props}>
          {children}
        </h4>
      );
    case 'h5':
      return (
        <h5 className={setType} {...props}>
          {children}
        </h5>
      );
    case 'h6':
      return (
        <h6 className={setType} {...props}>
          {children}
        </h6>
      );
    default:
      return (
        <p className={setType} {...props}>
          {children}
        </p>
      );
  }
};

Heading.propTypes = {
  theme: Proptypes.object.isRequired,
  children: Proptypes.node,
  as: Proptypes.node,
  type: Proptypes.string,
  className: Proptypes.string
};

Heading.defaultProps = {
  as: 'p',
  children: ''
};

export default withTheme(Heading);
