import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import WhiteBox from 'components/Forms/WhiteBox';
import { useStyles } from './style';
import Heading from 'components/Forms/Heading';
import Button from 'components/Forms/Button';
import { withTheme } from 'react-jss';
import face3 from 'images/face3p.svg';
import face4 from 'images/face4p.svg';
import { useHistory } from 'react-router-dom';
import { verifyEmail, verifyPersonalEmail } from 'apiServices/app';
import { CustomMessage } from 'components/UI';

const EmailVerification = ({ theme, match, personalEmailVerification }) => {
  const [emailVerified, setEmailVerified] = useState(true);
  const [error, setError] = useState({ apiError: false, message: '' });
  const history = useHistory();
  const classes = useStyles({ theme });
  const handleClick = () => {
    history.push('/login');
  };

  useEffect(() => {
    apiCall();
  }, []);

  const apiCall = () => {
    const {
      params: { token = '' }
    } = match;

    if (personalEmailVerification) {
      return verifyPersonalEmail({ token })
        .then(() => {
          setEmailVerified(true);
        })
        .catch(({ error }) => {
          setError({
            apiError: true,
            message: error
          });
        });
    }
    verifyEmail({ token })
      .then(() => {
        setEmailVerified(true);
      })
      .catch(({ error }) => {
        setError({
          apiError: true,
          message: error
        });
      });
  };
  const { apiError, message } = error;
  return (
    <>
      {apiError && <CustomMessage content={message} />}
      <div className={` ${classes.fullHeight}`}>
        <div className={classes.main}>
          <WhiteBox>
            {emailVerified ? (
              <img src={face4} className={classes.faceImage} alt="verified" />
            ) : (
              <img src={face3} className={classes.faceImage} alt="unverified" />
            )}
            <Heading as="h3" className={classes.heading}>
              {emailVerified ? 'Email Successfully Verified' : 'Please wait...'}
            </Heading>

            <p className={classes.p}>
              {emailVerified
                ? 'Thank you for your time! Your email is verified now, please login.'
                : 'Things are taking some time please be patient, soon your email will be verfied and you can access your dashboard.'}
            </p>
            <Button
              secondaryButton={true}
              className={classes.button}
              onClick={handleClick}
            >
              Log In
            </Button>
          </WhiteBox>
        </div>
      </div>
    </>
  );
};

EmailVerification.propTypes = {
  theme: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  personalEmailVerification: PropTypes.bool
};
EmailVerification.defaultProps = {
  personalEmailVerification: false
};
export default withTheme(EmailVerification);
