export const step1Initial = {
  email: '',
  email_confirmation: '',
  password: '',
  password_confirmation: '',
  role_id: 0,
  accept_conditions: false,
  registration_step: 1
};

export const step1ErrorInitial = {
  email: '',
  email_confirmation: '',
  password: '',
  password_confirmation: '',
  role_id: '',
  accept_conditions: ''
};
