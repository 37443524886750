/* eslint-disable max-len */
import { requestQuoteImg, requestQuoteImg2, requestQuoteImg3 } from '../images';
export const webUrl = 'https://www.lyfetech.io';
export const contactMail = 'wellbeing@lyfetech.io';
export const userRole = {
  PROFESSIONAL: 1,
  EMPLOYER: 2,
  ADMIN: 3,
  SME: 4,
  EMPLOYEE: 5
};
export const userAddressType = {
  PRIMARY: 1,
  BILLING: 2
};
export const accountStatus = { verified: 1, unverified: 2, registered: 3 };

export const services = {
  1: 'Yoga',
  2: 'Desk Side Yoga',
  3: 'Meditation',
  4: 'Meditation',
  5: 'Mindfulness',
  6: 'Mindfulness',
  7: 'Special event'
};

export const userExperiance = {
  1: 'Less than 2 years',
  2: '2 to 5 years',
  3: '5 or more years'
};

export const colorList = {
  1: 'green-tag',
  2: 'green-tag',
  3: 'orange-tag',
  4: 'orange-tag',
  5: 'green-tag',
  6: 'green-tag',
  7: 'purple-tag'
};

export const workplaceList = {
  1: 'No culture around wellbeing exists in the workplace',
  2: 'Some cultural focus on employee wellbeing, exists in isolated teams',
  3: 'Strong cultural focus around wellbeing at all levels and is lead top down'
};

export const statsClassList = {
  1: 'yoga-rating',
  2: 'yoga-rating',
  3: 'meditation-rating',
  4: 'meditation-rating',
  5: 'mindfulness-rating',
  6: 'mindfulness-rating',
  7: 'special-event-rating'
};

export const officePercentageList = {
  1: '<20%',
  2: '21 - 40%',
  3: '41 - 60%',
  4: '61 - 80%',
  5: '81 - 100%'
};

export const colorHeadingList = {
  1: 'green-colored-heading',
  2: 'green-colored-heading',
  3: 'orange-colored-heading',
  4: 'orange-colored-heading',
  5: 'green-colored-heading',
  6: 'green-colored-heading',
  7: 'purple-colored-heading'
};

export const colorItemList = {
  1: 'yoga-li',
  2: 'yoga-li',
  3: 'meditation-li',
  4: 'meditation-li',
  5: 'mindfulness-li',
  6: 'mindfulness-li',
  7: 'specialevent-li'
};

export const ratingIcons = {
  1: 'company-rating-1',
  2: 'company-rating-2',
  3: 'company-rating-3',
  4: 'company-rating-4',
  5: 'company-rating-5'
};

export const reasonList = {
  1: 'Reduced sickness in employees',
  2: 'Reduced absences due to sickness',
  3: 'Increased productivity',
  4: 'Increased efficiency',
  5: 'Improved employee attraction',
  6: 'Improved employee retention',
  7: 'Improved employee morale',
  8: 'Improved employee engagement'
};

export const bookingStatus = {
  PENDING: 1,
  BOOKED: 2,
  CANCEL: 3,
  COMPLETED: 4
};

export const dayLightSaving = {
  STARTMONTH: '03',
  ENDMONTH: '10',
  DAYLIGHTOFFSET: '1 hours'
};

export const bookingStatusText = {
  1: 'Pending',
  2: 'Booked',
  3: 'Cancel',
  4: 'Completed'
};

export const paidStatus = { Paid: 1 };
export const LongDateFormat = 'dddd, DD MMMM YYYY';
export const dateFormat = 'DD/MM/YYYY';
export const deliveryTypes = { 1: 'Face to Face', 2: 'Remote' };
export const F2F = 1;
export const SPL_EVENT = 7;
export const deliveryMethod = { ONSITE: 1, VIRTUAL: 2 };
export const ST_REGISTRATION_STEPS = {
  BASIC: 1,
  CONTACT: 2,
  EXPERIENCE: 3,
  DOCUMENT: 4,
  ADDRESS: 5
};
export const travelTimeOptions = [
  {
    value: 15,
    text: '15 Minutes'
  },
  {
    value: 30,
    text: '30 Minutes'
  },
  {
    value: 45,
    text: '45 Minutes'
  },
  {
    value: 60,
    text: '60 Minutes'
  },
  {
    value: 75,
    text: '75 Minutes'
  },
  {
    value: 90,
    text: '90 Minutes'
  }
];

export const servicesOffered = [
  {
    value: 1,
    text: 'Strategy & Assessment'
  },
  {
    value: 2,
    text: 'Awareness workshops'
  },
  {
    value: 3,
    text: 'Community (Groups Activities)'
  },
  {
    value: 4,
    text: '1-2-1 Services'
  }
];
export const mainServiceOffered = {
  STRATEGY_AND_ASSESMENT: 1,
  AWARENESS_WORKSHOPS: 2,
  COMMUNITY: 3,
  ONE_TWO_ONE_SERVICES: 4
};

export const additionalSessionInfo = [
  {
    id: 1,
    title: 'Request a quote for something else',
    description: "You would like to deliver a session type that isn't listed",
    btnText: 'REQUEST A QUOTE',
    image: requestQuoteImg,
    actionPath: '/request-quote'
  },
  {
    id: 2,
    title: 'Create an event of your own',
    description:
      'You have an event planned and would like to use wellbeing@lyfetech.io to Administrate only',
    image: requestQuoteImg2,
    btnText: 'CREATE AN EVENT',
    actionPath: '/request-event'
  },
  {
    id: 3,
    title: 'Not sure?',
    description:
      'Get in touch and speak to one of the team to discuss your needs',
    image: requestQuoteImg3,
    btnText: 'EMAIL US',
    actionPath: 'mailto:wellbeing@lyfetech.io'
  }
];
export const v2EventTypes = { normalEvent: 1, specialEvent: 7 };

export const eventTypesList = [
  {
    value: '1',
    text: 'Yoga'
  },
  {
    value: '3',
    text: 'Meditation'
  },
  {
    value: '5',
    text: 'Mindfulness'
  },
  {
    value: '7',
    text: 'Special event'
  }
];

export const eventTypeObj = {
  1: {
    thumb: 'sessions/yoga-face-to-face.png',
    description:
      'We invite you to a yoga sessions suitable for everyone. The sequences are not set in stone as our teacher will bring their experience to tailor mat based classes to your needs, whether that be dynamic or a more restorative class. The practice will encompass movement through physical postures, breath control and meditation.'
  },
  2: {
    thumb: 'sessions/yoga-remote.png',
    description:
      'A guided sequence of gentle poses and breathing techniques that are carried out over a virtual streaming service. A perfect way to take sometime to focus on you, ease any built up stress or tension to bring about a feeling of lightness, exhilaration and openness for the rest of the day. This is chair based so is suitable to participate in work attire.'
  },
  3: {
    thumb: 'sessions/meditation-face-to-face.png',
    description:
      'We invite you to transform the mind by participating in this guided meditation. Our teacher will bring their experience to tailor a session around key themes and may use a variety of concentration meditation techniques. The goal is to really experience whatever you are focusing on, whether that be your breath, a specific object or phrase. A perfect way to take some time to focus on you and ease any built up stress or tension to bring about a feeling of lightness, exhilaration and openness for the rest of the day. This is chair based so is suitable to participate in work attire.',
    remember: ''
  },
  4: {
    thumb: 'sessions/meditation-remote.png',
    description:
      'We invite you to transform the mind by participating in this guided meditation. Our teacher will bring their experience to tailor a session around key themes and may use a variety of concentration meditation techniques. The goal is to really experience whatever you are focusing on, whether that be your breath, a specific object or phrase. A perfect way to take some time to focus on you and ease any built up stress or tension to bring about a feeling of lightness, exhilaration and openness for the rest of the day. This is chair based so is suitable to participate in work attire.',
    remember: ''
  },
  5: {
    thumb: 'sessions/mindfullness-face-to-face.png',
    description:
      'We invite you to this mindfulness session to see if together we can become more aware in this present moment without casting judgement or setting expectation. The session is  based on the experience of the coach and goals outlined at the start of each session. Over a series, topics may focus on:  self esteem, positive thinking, self-compassion and cultivating happiness. Techniques in this class may include: body scans, light mindful practices and mindfulness-based stress reduction (MBSR) techniques. This is chair based so is suitable to participate in work attire.'
  },
  6: {
    thumb: 'sessions/mindfullness-remote.png',
    description:
      'We invite you to this mindfulness session to see if together we can become more aware in this present moment without casting judgement or setting expectation. The session is  based on the experience of the coach and goals outlined at the start of each session. Over a series, topics may focus on:  self esteem, positive thinking, self-compassion and cultivating happiness. Techniques in this class may include: body scans, light mindful practices and mindfulness-based stress reduction (MBSR) techniques. This is chair based so is suitable to participate in work attire.',
    remember: ''
  },
  7: {
    thumb: 'sessions/yoga-remote.png',
    description: '',
    remember: ''
  }
};

export const sessionRememberText = {
  1: `Arrive 5 minutes before the starting time
  \nRemember to bring a water bottle
  \nWear loose fitting, comfortable clothing suitable for moving and stretching in. We suggest stretchy leggings, tops, t-shirts, shorts. You may also bring a jumper and socks handy for relaxation at the end of class
  \nAt the start of class, communicate to your teacher if you have any injuries, or conditions our teachers should be aware of`,
  2: `Please join promptly at the start time, if the session has started be aware of others and remain on mute unless instructed otherwise.
  \nRemote sessions will display the teacher on video, while it is not necessary for you to also join on video, if you feel comfortable it is encouraged to do so to create more of a community.
  \nFeel free to leave comments in the chat window throughout, at a suitable moment in the session or following the session the teacher will try and answer them.
  \nRemember to bring a water bottle to stay hydrated.
  \nWearing work attire is suitable for these sessions.
  \nIf you are in an open-plan office and if everyone isn't participating you may want to find a meeting room or quiet corner.`
};

export const LIST_CONSTANT = {
  type: {
    UPCOMING: 0,
    COMPLETED: 1,
    PENDING: 2
  },
  professionalListType: {
    UPCOMING: 1,
    COMPLETED: 2,
    PENDING: 3
  },
  title: {
    UPCOMING: 'Upcoming',
    COMPLETED: 'Completed',
    PENDING: 'Pending'
  }
};
export function getSessionType(type) {
  let list = ['Upcoming', 'Completed', 'Pending'];
  if (type !== '') {
    return list[type];
  } else {
    return list;
  }
}

export function getProfessionalType(type) {
  let list = ['Verified', 'Unverified', 'Registered'];
  if (type !== '') {
    return list[type];
  } else {
    return list;
  }
}

export const API_DATA_LIMIT = 20;
export const SESSION_VIEW = ['sessionList', 'addSession', 'sessionDetail'];
export const interestGroupTabs = [
  'Details',
  'Personalisation',
  'Interest Group Content'
];
export const interestGroupAdminTabs = [
  'Company Management',
  'Personalisation',
  'Reward Management',
  'Interest Group Content'
];
export const insightsTabs = [
  'Overview',
  'Wellbeing',
  'Feedback',
  'Trends',
  'Sessions',
  'Preferences'
];
export const insightsTabsAdmin = [
  'Overview',
  'Wellbeing',
  'Feedback',
  'Trends',
  'Sessions',
  'KPIs',
  'Preferences'
];
export const AdminCompanySettingTabs = [
  'Details',
  'Personalisation',
  'Notes',
  'Interest Group Content',
  'Library'
];
export const AdminInterestGroupAdminTabs = [
  'Company Management',
  'Personalisation',
  'Reward Management',
  'Interest Group Content',
  'Library',
  'Notes'
];
export const SMESettingTabs = [
  { slug: 'company', name: 'Company Profile', id: 0, visibleAfterStep: 3 },
  { slug: 'service', name: 'Service Offerings', id: 1, visibleAfterStep: 4 },
  { slug: 'document', name: 'Documents & Uploads', id: 2, visibleAfterStep: 5 },
  { slug: 'billing', name: 'Billing', id: 3, visibleAfterStep: 6 },
  { slug: 'feedback', name: 'Session Feedback', id: 4, visibleAfterStep: 7 }
];
export const EMPDashboardTabs = [
  { slug: 'WorkPlace', name: 'Live Session', id: 1, visibleAfterStep: 2 },
  { slug: 'Personal', name: 'Personal', id: 2, visibleAfterStep: 2 },
  { slug: 'MyRewards', name: '1-2-1s', id: 5, visibleAfterStep: 2 },
  { slug: 'InsightsGraph', name: 'Insights', id: 6, visibleAfterStep: 2 },
  { slug: 'Library', name: 'Library', id: 4, visibleAfterStep: 2 },
  { slug: 'Feedback', name: 'Feedback', id: 3, visibleAfterStep: 2 },
  { slug: 'Settings', name: 'Settings', id: 0, visibleAfterStep: 1 }
];
export const StSettingTabs = [
  { slug: 'detail', name: 'Details & delivery', id: 0, visibleAfterStep: 5 },
  {
    slug: 'document',
    name: 'Experience, references & documents',
    id: 1,
    visibleAfterStep: 6
  },
  { slug: 'billing', name: 'Billing', id: 2, visibleAfterStep: 6 },
  { slug: 'feedback', name: 'Session feedback', id: 3, visibleAfterStep: 7 }
];
export const smeOnboardingStep = {
  company: 4,
  service: 5,
  document: 6,
  billing: 7,
  verification: 8
};
export const stOnboardingStep = {
  details: 6,
  document: 7,
  billing: 8,
  verification: 9
};
export const colorPickerText = {
  main: {
    heading: 'Main colour',
    text:
      'This is the main colour. It is used for buttons, links and some extra titles. Note that button text is white and wont be seen on lighter shades.',
    colorCode: '#36C2D9'
  },
  extra: {
    heading: 'Extra colour',
    text:
      'This colour is used for secondary areas of a page, such as banners and some smaller titles. It should compliment the Main Colour.',
    colorCode: '#36C2D9'
  },
  secondary: {
    heading: 'Secondary text colour',
    text:
      'This colour will be used for additional  elements, such as inactive areas of the menu. We recommend a greyscale shade.',
    colorCode: '#908A99'
  },
  backgroud: {
    heading: 'Background colour',
    text:
      'This colour will be used for the background. It can be changed, but remember to make sure dark text is legible.',
    colorCode: '#EEEDF2'
  }
};

export const otherThemeColors = {
  white: '#FFFFFF',
  black: '#000000',
  baseBlack: '#3C3E44',
  grayDark: '#908A99',
  grayMedium: '#CCC9D1',
  grayLight: '#EEEDF2',
  baseFiolet: '#36C2D9',
  orange: '#F9965E',
  blue: '#36C2D9',
  darkblue: '#36C2D9',
  green: '#3C9DB4',
  red: '#F35C7C',
  alertRed: '#CA1A30',
  greenDark: '#1D6E88',
  darkYellow: '#9F6000',
  gray: '#FAFAFA',
  lightgrey: '#CACACE',
  heather: '#ADB8C0',
  pink: '#F35C7C',
  magnolia: '#E9E6F0'
};

export const themeDefaultColor = {
  mainColor: '#36C2D9',
  extraColor: '#3C9DB4',
  secondaryColor: '#908A99',
  backgroudColor: '#EDECF2',
  buttonColor: '#FFFFFF',
  otherThemeColors
};

export const buttonDefaultColors = {
  white: '#FFFFFF',
  black: '#000000'
};

export const rgba = {
  white05: 'rgba(0,0,0,0.05)',
  white01: 'rgba(0,0,0,0.1)',
  black01: 'rgba(255,255,255,0.1)',
  black02: 'rgba(0,0,0,0.75)'
};
export const colourPopUpText = {
  withoutSave: {
    heading: 'wait...',
    text:
      'You have made some changes. Are you sure you want to continue without saving?'
  },
  onSave: {
    heading: 'Save',
    text: 'You have made some changes. Are you sure you want to save?'
  }
};

export const editLogoText = {
  heading: 'Your Logo',
  text:
    'You can upload your company logo here. Make sure it is visible against the colour scheme you have selected!'
};

export const editEmployeeBannerText = {
  heading: 'Employee Dashboard Background',
  text:
    'You can upload a background to your employee dashboard here. Make sure it is visible against the colour scheme you have selected! (ie. 16X9 image size limit 2mb)',
  subHeading:
    'Note : fit image within the full edit frame so that the image sizes correctly in the employee portal, avoid whitespace around the boarder.'
};
export const companyNotesText = {
  heading: 'Add new note',
  paragraph: 'These notes are only visible for you.',
  footerHeading: 'Note',
  saveText: 'Save this note',
  noContent: 'No notes have been provided. Click Add New Note to get started',
  placeholder: 'This company will be...',
  saveButton: 'Save'
};

export const ACTIONS = { OPEN: 1, CLOSE: 0 };

export const supportedFileformats =
  'doc,docx,odt,pdf,xls,xlsx,ods,ppt,pptx,txt,jpeg,jpg,png,tiff,gif,bmp';

export const environment = { dev: 'development', prod: 'production' };

export const filterPriceOptions = [
  {
    value: { min: 1, max: 250 },
    text: '£0 - £250'
  },
  {
    value: { min: 251, max: 500 },
    text: '£251 - £500'
  },
  {
    value: { min: 501, max: 750 },
    text: '£501 - £750'
  },
  {
    value: { min: 751, max: 1000 },
    text: ' £751 - £1000'
  },
  {
    value: { min: 1001, max: 1000000 },
    text: ' £1000+'
  }
];

export const durationOptions = [
  {
    value: { min: 1, max: 15 },
    text: '0 - 15 min'
  },
  {
    value: { min: 15, max: 30 },
    text: '15 - 30 min'
  },
  {
    value: { min: 30, max: 60 },
    text: '30 - 60 min'
  },
  {
    value: { min: 60, max: 90 },
    text: ' 60 - 90 min'
  },
  {
    value: { min: 90, max: 1000 },
    text: 'other'
  }
];

export const mediumOptions = [
  {
    value: 0,
    text: 'All'
  },
  {
    value: 1,
    text: 'Video'
  },
  {
    value: 2,
    text: 'Audio'
  },
  {
    value: 3,
    text: 'Blog'
  },
  {
    value: 4,
    text: 'PDF'
  }
];

export const openmind_sme = process.env.REACT_APP_OM_SME_EMAIL;

export const completeProfileCompanyStep4 = [
  {
    value: 1,
    text: 'Reduced sickness in employees'
  },
  {
    value: 2,
    text: 'Reduced absences due to sickness'
  },
  {
    value: 3,
    text: 'Increased productivity'
  },
  {
    value: 4,
    text: 'Increased efficiency'
  },
  {
    value: 5,
    text: 'Improved employee attraction'
  },
  {
    value: 6,
    text: 'Improved employee retention'
  },
  {
    value: 7,
    text: 'Improved employee morale'
  },
  {
    value: 8,
    text: 'Improved employee engagement'
  }
];
export const completeCompanyProfileStepForms = {
  first: 1,
  second: 2,
  third: 3,
  fourth: 4
};
export const completeCompanyProfileRegistrationSteps = {
  interestgroup: 3,
  activities: 4,
  expectation: 5,
  setPassword: 6
};

export const employerDashboardPages = {
  profile: 'profile',
  session: 'session',
  library: 'library',
  insights: 'insights'
};

export const attendeeReminderEmail = [
  {
    value: 0,
    text: 'OFF'
  },
  {
    value: 1,
    text: '15 Minutes'
  },
  {
    value: 2,
    text: '30 Minutes'
  }
];

export const sessionPrefer = [
  {
    value: 1,
    text: 'Office'
  },
  {
    value: 2,
    text: 'Virtual'
  },
  {
    value: 3,
    text: 'Both'
  }
];

export const employeeFeedbackReminder = [
  {
    value: 0,
    text: 'Email reminder off'
  },
  {
    value: 1,
    text: 'Email reminder on'
  }
];

export const employeeMarketing = [
  {
    value: true,
    text: 'Allow'
  },
  {
    value: false,
    text: 'OFF'
  }
];

export const employeeReminderEmail = [
  {
    value: 0,
    text: 'OFF'
  },
  {
    value: 1,
    text: 'Weekly'
  },
  {
    value: 2,
    text: 'Monthly'
  }
];

export const attendeeReminderStatus = {
  0: 'OFF',
  1: '15 Minutes',
  2: '30 Minutes'
};

export const attendeeStatus = {
  attended: 1
};

export const employeeWorkPlace = [
  {
    value: 1,
    text: 'Company Wellbeing Programme'
  },
  {
    value: 2,
    text: 'Run Club'
  },
  {
    value: 3,
    text: 'Project Team wellbing'
  },
  {
    value: 4,
    text: 'Company Yoga Club'
  }
];

export const employementStatus = [
  {
    value: 1,
    title: 'Sole traders',
    text:
      'Self-employed individuals who do not operate under a Personal Services Company, PSC.'
  },
  {
    value: 2,
    title: 'Personal Services Company (PSC, no Umbrella Company)',
    text:
      'If you are Personal Services Company (PSC, no Umbrella Company) operating a PSC, for some clients you will no longer be able to deliver services to due to the conditions of IR35.'
  },
  {
    value: 3,
    title: 'PAYE Umbrella Company (PSC)',
    text:
      'Individuals operating via a PSC and is paid via an umbrella company to ensure correct alignment to off-payroll requirements.'
  },
  {
    value: 4,
    title: 'B2B Services (with confirmed PAYE Structure)',
    text:
      'Will come under our standard operating terms, however must confirm that any workers (Facilitators) provided are on a PAYE model.'
  },
  {
    value: 5,
    title: 'Charity (with confirmed PAYE Structure)',
    text:
      'will come under our standard operating terms, however must confirm that any workers (Facilitators) provided are on a PAYE model.'
  }
];
export const dayOfWeek = [
  {
    value: 1,
    text: 'Monday'
  },
  {
    value: 2,
    text: 'Tuesday'
  },
  {
    value: 3,
    text: 'Wednesday'
  },
  {
    value: 4,
    text: 'Thursday'
  },
  {
    value: 5,
    text: 'Friday'
  },
  {
    value: 6,
    text: 'Saturday'
  },
  {
    value: 7,
    text: 'Sunday'
  }
];

export const timeOfDay = [
  {
    value: 1,
    text: 'Pre - 09:00'
  },
  {
    value: 2,
    text: '09:00 - 12:00'
  },
  {
    value: 3,
    text: '12:00 - 14:00'
  },
  {
    value: 4,
    text: '14:00 - 16:00'
  },
  {
    value: 5,
    text: '16:00 - 17:00'
  },
  {
    value: 6,
    text: '17:00 - 18:00'
  },
  {
    value: 7,
    text: '18:00 - 19:00'
  }
];

export const durationTime = [
  {
    value: 1,
    text: '20 Minutes'
  },
  {
    value: 2,
    text: '30 Minutes'
  },
  {
    value: 3,
    text: '40 Minutes'
  },
  {
    value: 4,
    text: '60 Minutes'
  },
  {
    value: 5,
    text: '60+ Minutes'
  }
];

export const motivationReasons = [
  {
    value: 1,
    text: 'Convenient access to a wellness session'
  },
  {
    value: 2,
    text: ' Opportunity to try something new'
  },
  {
    value: 3,
    text: 'Makes efficient use of time, as available at work'
  },
  {
    value: 4,
    text: 'To unwind, de-stress and provide clarity'
  },
  {
    value: 5,
    text: 'Unsure, but wanted to try it'
  }
];

export const experienceWithWellness = [
  {
    value: 1,
    text: 'I have never been to wellbeing sessions before'
  },
  {
    value: 2,
    text: 'I attend  1-2 sessions a year'
  },
  {
    value: 3,
    text: ' I attend sessions each month'
  },
  {
    value: 4,
    text: 'I attend sessions weekly'
  },
  {
    value: 5,
    text: 'I attend sessions daily'
  }
];

export const employeeAge = [
  {
    value: 1,
    text: '18-20 years old'
  },
  {
    value: 2,
    text: '21-30 years old'
  },
  {
    value: 3,
    text: '31-40 years old'
  },
  {
    value: 4,
    text: '41-50 years old'
  },
  {
    value: 5,
    text: '51-60 years old'
  },
  {
    value: 6,
    text: '60+ '
  },
  {
    value: 7,
    text: 'Prefer not to say'
  }
];

export const employeeGender = [
  {
    value: 1,
    text: 'Male'
  },
  {
    value: 2,
    text: 'Female'
  },
  {
    value: 3,
    text: 'Trans'
  },
  {
    value: 4,
    text: 'Non-binary'
  },
  {
    value: 5,
    text: 'Prefer not to disclose'
  },
  {
    value: 6,
    text: 'Prefer to self-describe'
  }
];
export const ethnicGroups = [
  {
    value: 1,
    text: 'English, Welsh, Scottish, Northern Irish or British',
    optgroup: 'White'
  },
  {
    value: 2,
    text: 'Irish',
    optgroup: 'White'
  },
  {
    value: 3,
    text: 'Gypsy or Irish Traveller',
    optgroup: 'White'
  },
  {
    value: 4,
    text: 'Any other White background',
    optgroup: 'White'
  },
  {
    value: 5,
    text: 'White and Black Caribbean',
    optgroup: 'Mixed or Multiple ethnic groups'
  },
  {
    value: 6,
    text: 'White and Black African',
    optgroup: 'Mixed or Multiple ethnic groups'
  },
  {
    value: 7,
    text: 'White and Asian',
    optgroup: 'Mixed or Multiple ethnic groups'
  },
  {
    value: 8,
    text: 'Any other Mixed or Multiple ethnic background',
    optgroup: 'Mixed or Multiple ethnic groups'
  },
  {
    value: 9,
    text: 'Indian',
    optgroup: 'Asian or Asian British'
  },
  {
    value: 10,
    text: 'Pakistani',
    optgroup: 'Asian or Asian British'
  },
  {
    value: 11,
    text: 'Bangladeshi',
    optgroup: 'Asian or Asian British'
  },
  {
    value: 12,
    text: 'Chinese',
    optgroup: 'Asian or Asian British'
  },
  {
    value: 13,
    text: 'Any other Asian background',
    optgroup: 'Asian or Asian British'
  },
  {
    value: 14,
    text: 'African',
    optgroup: 'Black, African, Caribbean or Black British'
  },
  {
    value: 15,
    text: 'Caribbean',
    optgroup: 'Black, African, Caribbean or Black British'
  },
  {
    value: 16,
    text: 'Any other Black, African or Caribbean background',
    optgroup: 'Black, African, Caribbean or Black British'
  },
  {
    value: 17,
    text: 'Arab',
    optgroup: 'Other ethnic group'
  },
  {
    value: 18,
    text: 'Any other ethnic group',
    optgroup: 'Other ethnic group'
  }
];

export const typeOfRole = [
  {
    value: 1,
    text: 'Sales'
  },
  {
    value: 2,
    text: 'Marketing & Communication'
  },
  {
    value: 3,
    text: 'Finance'
  },
  {
    value: 4,
    text: 'Customer Service'
  },
  {
    value: 5,
    text: 'Human Resource'
  },
  {
    value: 6,
    text: 'IT'
  },
  {
    value: 7,
    text: 'Legal'
  },
  {
    value: 8,
    text: 'Operations'
  },
  {
    value: 9,
    text: 'Field-based'
  },
  {
    value: 10,
    text: 'Other'
  }
];

export const B2CServiceCategoryType = [
  { key: 1, text: 'Mind', value: 1 },
  { key: 2, text: 'Body', value: 2 }
];

export const rewardScreens = {
  myRewards: 0,
  rewardSessionBlock: 1,
  hospitality: 2
};

export const transactionType = [
  {
    value: 1,
    text: 'Daily check in'
  },
  {
    value: 2,
    text: 'Wellbeing session attendance & feedback'
  },
  {
    value: 3,
    text: 'Balance Transfer'
  },
  {
    value: 4,
    text: 'Allocation'
  },
  {
    value: 5,
    text: '1 to 1 session booking'
  },
  {
    value: 6,
    text: 'Lyfe tour - Bonus'
  },
  {
    value: 7,
    text: 'Reconciliation'
  }
];
export const employeeRedirectUrls = {
  setting: {
    pathname: '/individual-dashboard/setting',
    search: '',
    state: { tab: 1 }
  },
  hospitality: {
    pathname: '/individual-dashboard/hospitality?restaurant',
    hash: 'tab=MyRewards',
    state: { tab: 5 }
  },
  rewards: {
    pathname: '/individual-dashboard/session-booking',
    search: '?tab=claimRewards',
    state: { tab: 2 }
  }
};

export const employeeBonusStep = {
  profileComplete: 1,
  cardLinked: 2,
  sessionBooked: 3,
  completeTour: 4
};

export const hideCardLinking = true;
export const termsAndConditions =
  'https://www.lyfetech.io/legal-cashback-networkb';
export const lyfetechExperianLandingPage =
  'https://www.lyfetech.io/experian-identity-protection';
