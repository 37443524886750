export const otherThemeColors = {
  white: '#FFFFFF',
  black: '#000000',
  baseBlack: '#3C3E44',
  grayDark: '#908A99',
  grayMedium: '#CCC9D1',
  grayLight: '#EEEDF2',
  baseFiolet: '#36C2D9',
  orange: '#F9965E',
  blue: '#36C2D9',
  green: '#3C9DB4',
  greenLight: '#1BD7B7',
  red: '#F35C7C',
  alertRed: '#CA1A30',
  greenDark: '#1D6E88',
  darkYellow: '#9F6000',
  gray: '#FAFAFA',
  lightgrey: '#CACACE',
  heather: '#ADB8C0',
  pink: '#F35C7C',
  magnolia: '#E9E6F0',
  alizarin: '#ED1C24',
  goldenYellow: '#FCE100',
  atlantis: '#91C83E',
  magnoliaLight: '#F4F2F6',
  darkgreen: '#2fa525',
  voiletLight: '#E9E6F0',
  grayMediumLight: '#22242626',
  darkGrayishRed: '#7b7575',
  lyfeOrange: '#FC6354'
};
const rgba = {
  white05: 'rgba(0,0,0,0.05)',
  white01: 'rgba(0,0,0,0.1)',
  white85: 'rgba(255, 255, 255, 0.85)',
  white20: 'rgba(255, 255, 255, 0.4)',
  black01: 'rgba(255,255,255,0.1)',
  black02: 'rgba(0,0,0,0.75)'
};
export const defaultColors = {
  mainColor: '#36C2D9',
  extraColor: '#3C9DB4',
  secondaryColor: '#908A99',
  backgroudColor: '#EDECF2',
  buttonColor: '#FFFFFF'
};
export default {
  ...defaultColors,
  ...{ otherThemeColors },
  ...{ rgba }
};
